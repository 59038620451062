<template>
  <main>
    <HeroSection id="hero" small />
    <GallerySection id="gallery" full :year="Number($route.params.year)" />
  </main>
</template>

<script>
/* eslint-disable */
import HeroSection from '../../components/HeroSection.vue'
import GallerySection from '../../components/GallerySection.vue'

export default {
  name: 'GalleryYear',

  components: {
    HeroSection,
    GallerySection,
  },
}
</script>
