<template>
  <main>
    <HeroSection id="hero" small />
    <ArchiveSection />
    <GallerySection id="gallery" full :year="2024" hide-year />
  </main>
</template>

<script>
/* eslint-disable */
import HeroSection from '../../components/HeroSection.vue'
import GallerySection from '../../components/GallerySection.vue'
import ArchiveSection from '../../components/ArchiveSection.vue'

export default {
  name: 'Gallery',

  components: {
    HeroSection,
    GallerySection,
    ArchiveSection,
  },
}
</script>
