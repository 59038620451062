import { computed } from '@vue/composition-api'

const ridePrefix = `RideAgainstBleeding_2021_s_`
const linkText = ``
const linkUrl = ``
const conferencePrefix = `koagulace_a_krvaceni_2021_s_`

const ridePrefix2022 = `RAB_2022_PrvniVarka_`
const rideImageCount2022 = 54
const linkText2022 = ``
const linkUrl2022 = ``

const ridePrefix2023 = `Ride-Againts-Bleeding_2023_`
const rideImageCount2023 = 72
const linkText2023 = `Koagulace a krvácení 2023 – fotograf Jan Petřík (honzapetrik.cz)`
const linkUrl2023 = `http://fotograf.honzapetrik.cz/koagulace-a-krvaceni-2023/`

const ridePrefix2024 = `Ride-Against-Bleeding_2024_`
const rideImageCount2024 = 57

const rideImageCount = 131
const conferenceImageCount = 119

function padWithZeros(num) {
  return (num < 10 ? '000' : num < 100 ? '00' : num < 1000 ? '0' : '') + num
}

export default function useGallery({ name, imageCount, year }) {
  const images = computed(() => {
    if (parseInt(year) === 2021) {
      const prefix = name === 'ride' ? ridePrefix : conferencePrefix
      const maxCount = name === 'ride' ? rideImageCount : conferenceImageCount
      imageCount = imageCount || maxCount
      const imageArray = []
      for (let i = 1; i <= imageCount && i <= maxCount; i++) {
        imageArray.push(`/gallery/${year}/${prefix}${padWithZeros(i)}.jpg`)
      }
      return imageArray
    } else if (parseInt(year) === 2022) {
      const prefix = ridePrefix2022
      const maxCount = rideImageCount2022

      imageCount = imageCount || maxCount
      const imageArray = []
      for (let i = 1; i <= imageCount && i <= maxCount; i++) {
        imageArray.push(`/gallery/${year}/${prefix}${padWithZeros(i)}.jpg`)
      }
      return imageArray
    } else if (parseInt(year) === 2023) {
      const prefix = ridePrefix2023
      const maxCount = rideImageCount2023

      imageCount = imageCount || maxCount
      const imageArray = []
      for (let i = 1; i <= imageCount && i <= maxCount; i++) {
        imageArray.push(`/gallery/${year}/${prefix}${padWithZeros(i)}.jpg`)
      }
      return imageArray
    } else if (parseInt(year) === 2024) {
      const prefix = ridePrefix2024
      const maxCount = rideImageCount2024

      imageCount = imageCount || maxCount
      const imageArray = []
      for (let i = 1; i <= imageCount && i <= maxCount; i++) {
        imageArray.push(`/gallery/${year}/${prefix}${padWithZeros(i)}.jpg`)
      }
      return imageArray
    } else return []
  })

  const link = computed(() => {
    if (parseInt(year) === 2021) {
      return {
        text: linkText,
        url: linkUrl,
      }
    } else if (parseInt(year) === 2022) {
      return {
        text: linkText2022,
        url: linkUrl2022,
      }
    } else if (parseInt(year) === 2023) {
      return {
        text: linkText2023,

        url: linkUrl2023,
      }
    } else return {}
  })

  return { images, link }
}
