<template>
  <main>
    <HeroSection id="hero" small />
    <v-container fluid>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm6 md4>
          <div class="my-10">
            <p>
              Registrace proběhla úspěšně.
              <br />
              Potvrzující e-mail přijde v nejbližší době.
            </p>
            <v-btn class="mt-10" link to="/">Zpět na úvodní stránku</v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </main>
</template>

<script>
import HeroSection from '../components/HeroSection.vue'

export default {
  components: {
    HeroSection,
  },
}
</script>
