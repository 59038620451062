<template>
  <main>
    <HeroSection id="hero" />
    <InfoSection id="about" />
    <RouteSection id="route" />
    <!-- <StorySection id="story" /> -->
    <GallerySection id="gallery" :year="2024" />
    <!-- <PromoSection id="promo" /> -->
    <!-- <PartnersSection id="partners" /> -->
  </main>
</template>

<script>
// import StorySection from '../components/StorySection.vue'
import HeroSection from '../components/HeroSection.vue'
import RouteSection from '../components/RouteSection.vue'
import InfoSection from '../components/InfoSection.vue'
import GallerySection from '../components/GallerySection.vue'
// import PromoSection from "../components/PromoSection.vue";
// import PartnersSection from '../components/PartnersSection.vue'

export default {
  name: 'Home',

  components: {
    HeroSection,
    InfoSection,
    // StorySection,
    RouteSection,
    GallerySection,
    // PromoSection,
    // PartnersSection,
  },
}
</script>
