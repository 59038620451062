<template>
  <section class="GallerySection">
    <div class="content">
      <h2>
        Fotogalerie <span v-if="!hideYear">{{ year }}</span>
      </h2>
      <p class="text-center" v-if="link.text">
        <span v-if="!link.url">{{ link.text }}</span>
        <a v-else :href="link.url" target="_blank" rel="noopener noreferrer">{{
          link.text
        }}</a>
      </p>
      <ThumbnailGrid>
        <div class="" v-for="image in rideImages" :key="`ride-${image}`">
          <v-img
            contain
            :aspect-ratio="1"
            class="GallerySection__Image"
            @click.stop="openDetail(image)"
            :src="image"
          ></v-img>
        </div>
      </ThumbnailGrid>
      <!-- <ThumbnailGrid>
        <div
          class=""
          v-for="image in conferenceImages"
          :key="`conference-${image}`"
        >
          <v-img
            contain
            :aspect-ratio="1"
            class="GallerySection__Image"
            @click.stop="openDetail(image)"
            :src="image"
          ></v-img>
        </div>
      </ThumbnailGrid> -->
      <div v-if="!full" class="GallerySection__Show">
        <v-btn depressed color="#ddd" to="/gallery">
          Zobrazit všechny fotografie
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="var(--contentWidth)"
      content-class="GallerySection__Dialog"
    >
      <img class="GallerySection__ImageDetail" :src="selectedImage" />
    </v-dialog>
  </section>
</template>

<script>
import useGallery from '../use/useGallery.js'
import ThumbnailGrid from '../components/ThumbnailGrid.vue'

export default {
  props: {
    full: {
      type: Boolean,
    },
    year: {
      type: Number,
      default: 2021,
    },
    hideYear: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ThumbnailGrid,
  },
  setup(props) {
    const rideImagesOptions = {
      name: 'ride',
      year: props.year,
    }
    const conferenceImageOptions = {
      year: props.year,
    }

    if (!props.full) {
      rideImagesOptions.imageCount = 12
      conferenceImageOptions.imageCount = 12
    }
    const { images: rideImages, link } = useGallery(rideImagesOptions)
    const { images: conferenceImages } = useGallery(conferenceImageOptions)

    return {
      rideImages,
      conferenceImages,
      link,
    }
  },
  data() {
    return {
      selectedImage: null,
      dialog: false,
    }
  },
  methods: {
    openDetail(image) {
      this.selectedImage = image
      this.dialog = true
    },
  },
}
</script>
<style lang="scss" scoped>
.GallerySection {
  &__Item {
    position: relative;
    width: 100%;
    height: 0%;
    padding-bottom: 100%;
  }
  &__Show {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__Image {
    cursor: pointer;
  }
  &__ImageDetail {
    max-height: calc(100vh - 7rem);
  }
}
</style>
<style lang="scss">
.GallerySection {
  &__Dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    width: unset !important;
    overflow: hidden !important;
  }
}
</style>
