<template>
  <section class="ArchiveSection">
    <div class="content text-right">
      <p>Fotogalerie předchozích ročníků:</p>
      <div class="d-flex justify-end">
        <router-link
          v-for="year in archivedYears"
          :key="year"
          :to="`/gallery/${year}`"
        >
          <v-btn class="mr-4" depressed color="red" dark rounded outlined>
            {{ year }}
          </v-btn>
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  setup() {
    return {
      archivedYears: [2024, 2023, 2022, 2021],
    }
  },
}
</script>
<style lang="scss">
.ArchiveSection {
  &__Content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: wrap;
    .v-image {
      margin: 1rem;
    }
    margin-bottom: 4rem;
  }
  &__Ribbon {
    padding: 2rem 0;
    --gray: 220;
    background-color: rgb(var(--gray), var(--gray), var(--gray));
    .PartnersSection__Content {
      margin: 0;
    }
  }
}
</style>
