<template>
  <v-app>
    <AppBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue'
import AppLogo from './components/AppLogo.vue'
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { AppBar, AppLogo },
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
<style lang="scss" scoped>
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgb(34, 34, 34);
  color: #fff;
  &__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 48rem;
    font-size: 2rem;
    text-align: center;
  }
  &__Logo {
    padding-bottom: 4rem;
    max-width: 24rem;
    margin: auto;
  }
}
.theme--light.v-application {
  background: #fbfaf9;
}
</style>
