<template>
  <section class="HeroSection">
    <v-img
      src="../assets/hero.jpg"
      :max-height="small ? '18rem' : '32rem'"
    ></v-img>

    <div class="HeroSection__LogoWrapper">
      <AppLogo class="HeroSection__Logo" />
      <p class="HeroSection__Date">1. května 2024</p>
    </div>
  </section>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue'

export default {
  name: 'HeroSection',
  props: {
    small: {
      type: Boolean,
    },
  },
  components: {
    AppLogo,
  },
}
</script>

<style lang="scss" scoped>
.HeroSection {
  position: relative;
  color: white;
  ::v-deep .v-image {
    &__image {
      background-position: center 20% !important;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(10px);
      mix-blend-mode: multiply;
    }
  }
  &__LogoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__Logo {
    max-width: 20rem;
  }
  &__Date {
    font-weight: 400;
    margin-top: 1rem;
    font-size: 1.2rem;
  }
}
</style>
