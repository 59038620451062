<template>
  <div id="app-bar" class="AppBar">
    <v-app-bar fixed elevate-on-scroll>
      <div class="AppBar__Content">
        <AppLogo class="AppBar__Logo" @click="redirectHome" />
        <v-btn-toggle v-model="activeMenuItem" group tile>
          <v-btn
            v-for="menuItem in menuItems"
            :key="menuItem.name"
            class="mr-2"
            @click="scrollTo(menuItem)"
          >
            {{ menuItem.name }}
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue'
export default {
  components: {
    AppLogo,
  },
  data() {
    return {
      activeMenuItem: null,
      menuItems: [
        {
          name: 'O akci',
          target: '#about',
        },
        {
          name: 'Trasa',
          target: '#route',
        },
        // {
        //   name: 'Ročníky',
        //   target: '#years',
        // },
        // {
        //   name: 'Černí koně',
        //   target: '#story',
        // },
        {
          name: 'Galerie',
          to: '/gallery',
        },
        {
          name: 'Registrace',
          to: '/registration',
        },
      ],
    }
  },
  methods: {
    async scrollTo({ target, to }) {
      if (to) {
        this.$router.push(to)
      } else {
        if (this.$route.path !== '/') {
          await this.$router.push('/')
        }
        await this.$nextTick()
        this.$vuetify.goTo(target)
      }
    },
    redirectHome() {
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.AppBar {
  &__Logo {
    max-height: 2rem;
    cursor: pointer;
  }
  &__Content {
    max-width: var(--contentWidth);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 0 1rem;
    @media screen and (min-width: 48rem) {
      padding: 0 2rem;
    }
    @media screen and (min-width: 64rem) {
      padding: 0 3rem;
    }
  }
  ::v-deep .v-toolbar__content {
    padding: 0 !important;
  }
  .v-app-bar {
    background-color: transparent !important;
    color: white;

    .v-btn {
      color: white;
    }

    &--is-scrolled {
      background-color: whitesmoke !important;
      color: black;

      .v-btn {
        color: black;

        &--active {
          color: red;
        }

        &::before {
          background-color: transparent;
        }
      }
    }
  }
}
</style>
