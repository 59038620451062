<template>
  <section class="RouteSection">
    <ContentRoute />
  </section>
</template>

<script>
import ContentRoute from './Content/Route.vue'

export default {
  name: 'RouteSection',
  components: {
    ContentRoute,
  },
}
</script>

<style lang="scss" scoped>
.v-image {
  border-radius: 1rem;
}
</style>
