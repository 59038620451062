<template>
  <main>
    <HeroSection id="hero" small />
    <v-container fluid>
      <v-layout row wrap justify-center>
        <v-flex xs11 sm10 md6 lg4>
          <v-card class="my-10">
            <v-card-title>
              <span class="headline">Registrace</span>
            </v-card-title>
            <v-card-text>
              <!-- v-alert -->
              <v-alert color="warning" v-if="alert">
                Při odesílání žádosti došlo k chybě. Zkontrolujte všechna pole a
                zkuste to znovu. Nebo nás kontaktujte na adrese
                <a href="mailto:info@corbiere.cz"> info@corbiere.cz </a>
              </v-alert>
              <RegistrationForm @submit="sendForm" />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </main>
</template>
<script>
import HeroSection from '../components/HeroSection.vue'
import RegistrationForm from '../components/RegistrationForm.vue'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const submitNetlify = async (form) =>
  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({ ...form, 'form-name': 'registration' }),
  })

const submitCloudFunction = async (form) =>
  fetch(
    `${
      process.env.NODE_ENV == 'development' ? 'http://localhost:9999' : ''
    }/.netlify/functions/register`,
    {
      method: 'POST',
      body: JSON.stringify(form),
    }
  )

export default {
  components: {
    RegistrationForm,
    HeroSection,
  },
  data() {
    return {
      alert: false,
    }
  },
  methods: {
    async sendForm(form) {
      this.alert = false
      try {
        const formSubmited = await submitNetlify(form)
        if (!formSubmited.ok) {
          this.alert = true
          return
        }
        const emailSent = await submitCloudFunction(form)
        if (!emailSent.ok) {
          this.alert = true
        }
      } catch (error) {
        this.alert = true
      }
      if (this.alert) {
        return
      } else {
        this.$router.push('/success')
      }
    },
  },
}
</script>
