<template>
  <section class="InfoSection">
    <ContentAbout />
    <!-- <LogosAbout class="mb-8" /> -->
  </section>
</template>

<script>
import ContentAbout from '@/components/Content/About.vue'
// import LogosAbout from '@/components/Logos/About.vue'

export default {
  components: {
    ContentAbout,
    // LogosAbout,
  },
}
</script>

<style></style>
