import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Registration from '../views/Registration.vue'
import Success from '../views/Success.vue'
import Gallery from '../views/Gallery'
import GalleryYear from '../views/Gallery/GalleryYear.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery,
  },
  // gallery for year
  {
    path: '/gallery/:year',
    name: 'GalleryYear',
    component: GalleryYear,
    props: {
      year: '2021',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((_from, _to, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
