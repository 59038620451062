<template>
  <v-form @submit.prevent="onSubmit">
    <v-text-field
      v-model="form.givenName"
      :label="texts.givenName.label"
      required
      @blur="v$.givenName.$touch()"
      @input="v$.givenName.$reset()"
      :error-messages="v$.givenName.$error ? texts.givenName.error : ''"
    ></v-text-field>
    <v-text-field
      v-model="form.familyName"
      :label="texts.familyName.label"
      required
      @blur="v$.familyName.$touch()"
      @input="v$.familyName.$reset()"
      :error-messages="v$.familyName.$error ? texts.familyName.error : ''"
    ></v-text-field>
    <v-text-field
      v-model="form.email"
      :label="texts.email.label"
      required
      @blur="v$.email.$touch()"
      @input="v$.email.$reset()"
      :error-messages="v$.email.$error ? texts.email.error : ''"
    ></v-text-field>
    <v-text-field
      v-model="form.honoricPrefix"
      :label="texts.honoricPrefix.label"
    ></v-text-field>
    <v-text-field
      v-model="form.honoricSuffix"
      :label="texts.honoricSuffix.label"
    ></v-text-field>
    <v-text-field
      v-model="form.phone"
      :label="texts.phone.label"
      @blur="v$.phone.$touch()"
      @focus="v$.phone.$reset()"
      :error-messages="v$.phone.$error ? texts.phone.error : ''"
    ></v-text-field>

    <v-divider class="my-2"></v-divider>
    <v-radio-group
      v-model="form.needsJersey"
      :label="texts.needsJersey.label"
      @blur="v$.needsJersey.$touch()"
      @focus="v$.needsJersey.$reset()"
      :error-messages="v$.needsJersey.$error ? texts.needsJersey.error : ''"
    >
      <v-radio :value="true" :label="texts.needsJersey.needs"></v-radio>
      <v-radio :value="false" :label="texts.needsJersey.has"></v-radio>
    </v-radio-group>
    <template v-if="form.needsJersey">
      <v-radio-group
        v-model="form.gender"
        :label="texts.gender.label"
        @blur="v$.gender.$touch()"
        @focus="v$.gender.$reset()"
        :error-messages="v$.gender.$error ? text.gender.error : ''"
      >
        <v-radio :value="'female'" :label="texts.gender.female"></v-radio>
        <v-radio :value="'male'" :label="texts.gender.male"></v-radio>
      </v-radio-group>
      <v-select
        v-if="form.gender"
        v-model="form.size"
        :label="texts.size.label"
        :required="form.needsJersey"
        :items="sizes"
        @blur="v$.size.$touch()"
        @focus="v$.size.$reset()"
        :error-messages="v$.size.$error ? texts.size.error : ''"
      >
        <template #prepend-item>
          <div class="px-4 grey lighten-3 mb-3">
            <v-row justify="space-between">
              <v-col cols="3" class="text-caption"> Velikost </v-col>
              <v-col cols="5" class="text-caption">
                <div>Obvod hrudi (cm)</div>
              </v-col>
              <v-col
                v-if="form.gender === 'male'"
                cols="4"
                class="text-caption"
              >
                <div>Výška (cm)</div>
              </v-col>
            </v-row>
          </div>
        </template>
        <template #item="{ item: { text, chestDiameter, height } }">
          <v-row justify="space-between">
            <v-col cols="3">
              {{ text }}
            </v-col>
            <v-col cols="5" class="text-caption">
              <div v-if="chestDiameter">
                {{ chestDiameter }}
              </div>
              <!-- {{ hipDiameter }} -->
            </v-col>
            <v-col cols="4" class="text-caption" v-if="form.gender === 'male'">
              <div v-if="height">
                {{ height }}
              </div>
              <!-- {{ hipDiameter }} -->
            </v-col>
          </v-row>
        </template>
      </v-select>
      <v-select
        v-if="form.gender"
        v-model="form.sizePants"
        :label="texts.sizePants.label"
        :required="form.needsJersey"
        :items="sizesPants"
        @blur="v$.sizePants.$touch()"
        @focus="v$.sizePants.$reset()"
        :error-messages="v$.sizePants.$error ? texts.sizePants.error : ''"
      >
        <template #prepend-item>
          <div class="px-4 grey lighten-3 mb-3">
            <v-row justify="space-between">
              <v-col cols="3" class="text-caption"> Velikost </v-col>
              <v-col cols="5" class="text-caption">
                <div>Obvod boků (cm)</div>
              </v-col>
            </v-row>
          </div>
        </template>
        <template #item="{ item: { text, hipDiameter } }">
          <v-row justify="space-between">
            <v-col cols="3">
              {{ text }}
            </v-col>
            <v-col cols="5" class="text-caption">
              <div v-if="hipDiameter">
                {{ hipDiameter }}
              </div>
            </v-col>
          </v-row>
        </template>
      </v-select>
    </template>

    <v-divider class="my-2"></v-divider>
    <v-radio-group
      v-model="form.needsBike"
      :label="texts.needsBike.label"
      @blur="v$.needsBike.$touch()"
      @focus="v$.needsBike.$reset()"
      :error-messages="v$.needsBike.$error ? texts.needsBike.error : ''"
    >
      <v-radio :value="true" :label="texts.needsBike.needs"></v-radio>
      <v-radio :value="false" :label="texts.needsBike.has"></v-radio>
    </v-radio-group>
    <v-radio-group
      v-if="form.needsBike"
      v-model="form.bike"
      :label="texts.bike.label"
      @blur="v$.bike.$touch()"
      @focus="v$.bike.$reset()"
      :error-messages="v$.bike.$error ? texts.bike.error : ''"
    >
      <v-radio :value="'bike'" :label="texts.bike.bike"></v-radio>
      <v-radio :value="'electricBike'" :label="texts.bike.electricBike">
        ></v-radio
      >
    </v-radio-group>

    <v-divider class="my-2"></v-divider>
    <v-checkbox
      v-model="form.confirmGDPR"
      :label="texts.confirmGDPR.label"
      required
      @blur="v$.confirmGDPR.$touch()"
      @focus="v$.confirmGDPR.$reset()"
      :error-messages="v$.confirmGDPR.$error ? texts.confirmGDPR.error : ''"
    />
    <v-btn class="mt-2" type="submit" :disabled="this.v$.$invalid"
      >Odeslat</v-btn
    >
  </v-form>
</template>
<script>
import { reactive, computed } from '@vue/composition-api'
import { useVuelidate } from '@vuelidate/core'
import { email, required, requiredIf } from '@vuelidate/validators'

export default {
  setup() {
    const form = reactive({
      givenName: '',
      familyName: '',
      honoricPrefix: '',
      honoricSuffix: '',
      email: '',
      phone: '',
      needsJersey: '',
      needsBike: '',
      bike: '',
      confirmGDPR: false,
      gender: '',
      size: '',
      sizePants: '',
    })
    const texts = {
      givenName: {
        label: 'Jméno',
        error: 'Vyplňte prosím své jméno',
      },
      familyName: {
        label: 'Příjmení',
        error: 'Vyplňte prosím své příjmení',
      },
      honoricPrefix: {
        label: 'Titul',
        error: '',
      },
      honoricSuffix: {
        label: 'Titul za jménem',
        error: '',
      },
      email: {
        label: 'E-mail',
        error: 'Vyplňte prosím platný e-mail.',
      },
      phone: {
        label: 'Telefon',
        error: 'Vyplňte prosím telefonní číslo.',
      },
      needsJersey: {
        label: 'Dres',
        error: 'Prosím, zvolte jednu z možností.',
        needs: 'Chci dres',
        has: 'Mám dres z minulého ročníku',
      },
      needsBike: {
        label: 'Kolo',
        error: 'Prosím, zvolte jednu z možností.',
        has: 'Mám vlastní kolo',
        needs: 'Chci zajistit kolo',
      },
      bike: {
        label: 'Typ kola',
        bike: 'Kolo',
        electricBike: 'Elektrokolo',
        error: 'Prosím, zvolte jednu z možností.',
      },
      confirmGDPR: {
        label: 'Souhlasím s GDPR',
        error: 'Musíte souhlasit s GDPR.',
      },
      gender: {
        label: 'Pohlaví',
        error: 'Prosím, zvolte jednu z možností.',
        male: 'Muž',
        female: 'Žena',
      },
      size: {
        label: 'Velikost dresu',
        error: 'Vyberte si prosím jednu z velikostí.',
      },
      sizePants: {
        label: 'Velikost kalhot',
        error: 'Vyberte si prosím jednu z velikostí.',
      },
    }
    const rules = {
      givenName: { required, $lazy: true },
      familyName: { required },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      needsJersey: {
        required,
      },
      gender: {
        required: requiredIf((v) => v.needsJersey),
      },
      size: {
        required: requiredIf((v) => v.needsJersey),
      },
      sizePants: {
        required: requiredIf((v) => v.needsJersey),
      },
      needsBike: {
        required,
      },
      bike: {
        required: requiredIf((v) => v.needsBike),
      },
      confirmGDPR: {
        required,
        mustAgree: () => form.confirmGDPR === true,
      },
    }
    const v$ = useVuelidate(rules, form)

    const sizesTemplateMale = [
      {
        text: 'S',
        value: 1,
        chestDiameter: '84-88',
        hipDiameter: '85-89',
        height: '175 a více',
      },
      {
        text: 'M',
        value: 2,
        chestDiameter: '88-92',
        hipDiameter: '89-93',
        height: '185 a více',
      },
      {
        text: 'M',
        value: 3,
        chestDiameter: '92-96',
        hipDiameter: '93-97',
        height: '195 a více',
      },
      {
        text: 'L',
        value: 4,
        chestDiameter: '96-100',
        hipDiameter: '97-101',
        height: '200 a více',
      },
      {
        text: 'L',
        value: 3,
        chestDiameter: '100-104',
        hipDiameter: '101-105',
      },
      {
        text: 'XL',
        value: 6,
        chestDiameter: '104-112',
        hipDiameter: '105-113',
      },
      {
        text: 'XXL',
        value: 7,
        chestDiameter: '112-120',
        hipDiameter: '113-121',
      },
      {
        text: 'XXXL',
        value: 8,
        chestDiameter: '120-128',
        hipDiameter: '121-129',
      },
    ]
    const sizesTemplateFemale = [
      {
        text: 'XS',
        value: 1,
        chestDiameter: '82-86',
        hipDiameter: '86-90',
      },
      {
        text: 'S',
        value: 2,
        chestDiameter: '86-90',
        hipDiameter: '90-94',
      },
      {
        text: 'M',
        value: 3,
        chestDiameter: '90-94',
        hipDiameter: '94-98',
      },
      {
        text: 'L',
        value: 4,
        chestDiameter: '94-98',
        hipDiameter: '98-102',
      },
      {
        text: 'XL',
        value: 5,
        chestDiameter: '98-106',
        hipDiameter: '102-110',
      },
      {
        text: 'XXL',
        value: 6,
        chestDiameter: '106-114',
        hipDiameter: '110-118',
      },
    ]

    const sizes = computed(() => {
      return form.gender === 'male' ? sizesTemplateMale : sizesTemplateFemale
    })
    const sizesPants = computed(() => {
      return form.gender === 'male' ? sizesTemplateMale : sizesTemplateFemale
    })

    return {
      v$,
      form,
      sizes,
      sizesPants,
      valid: true,
      texts,
    }
  },
  methods: {
    onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      this.$emit('submit', this.form)
    },
  },
}
</script>
